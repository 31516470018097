let serverClientTimeDiff: number | undefined
let fetchPromise: Promise<void> | undefined
let lastFetchTime: number | undefined

function fetchTimeDiff(): Promise<void> | undefined {
  const requestStartTime = Date.now()
  // Don't fetch if there's already a request in progress
  if (fetchPromise) return

  // Only fetch if we haven't fetched before or if it's been more than an hour
  if (lastFetchTime && requestStartTime - lastFetchTime < 3600000) return

  fetchPromise = (async () => {
    try {
      const response = await fetch('https://time.akamai.com/?iso')
      if (!response.ok) {
        throw new Error('A network error occurred when fetching server time')
      }

      const responseTime = Date.now()
      // If the request took more than 30 seconds, discard it as unreliable
      // This happens for example when a tab is inactive for a while
      if (responseTime - requestStartTime > 30000) {
        console.warn('Server time fetch took too long, discarding result')
        return
      }

      const serverTimeISO = await response.text()
      const serverTime = new Date(serverTimeISO)
      // Use responseTime instead of requestStartTime for more accurate diff
      serverClientTimeDiff = serverTime.getTime() - responseTime
    } catch (error) {
      console.error('Error fetching server time:', error)
      serverClientTimeDiff = 0
    } finally {
      lastFetchTime = requestStartTime
      fetchPromise = undefined
    }
  })()
}

export const getCurrentTimeDiff = () => serverClientTimeDiff

// The history behind this is that we dont want to load live event countdowns
// before the event has started, and some devices seem to have the wrong time set
export const getServerTime = () => {
  fetchTimeDiff()

  if (serverClientTimeDiff === undefined) {
    return new Date(Date.now())
  }
  return new Date(Date.now() + serverClientTimeDiff)
}
