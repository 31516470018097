import { SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react'
import type { SinglePanelFieldsFragment } from '@nordic-web/gql'
import { MultiHero } from '@/components/hero/multi-hero'
import { SinglePanel } from '@/features/panel-factory/components/single-panel'

type MultiSinglePanel = {
  panels: SinglePanelFieldsFragment[]
}

type SlideProps = {
  panel: SinglePanelFieldsFragment
  index: number
}

function Slide({ panel, index }: SlideProps) {
  const swiper = useSwiper()
  const { isActive } = useSwiperSlide()

  function onTrailerPlay() {
    swiper.autoplay.stop()
  }

  function onTrailerEnd() {
    setTimeout(() => {
      if (isActive) {
        swiper.slideNext()
        swiper.autoplay.start()
      }
    }, 4000)
  }

  return (
    <SinglePanel
      index={index}
      panel={panel}
      onTrailerEnd={onTrailerEnd}
      onTrailerPlay={onTrailerPlay}
      isActive={isActive}
    />
  )
}

export function MultiSinglePanel({ panels }: MultiSinglePanel) {
  return (
    <MultiHero>
      {panels.map((panel, index) => (
        <SwiperSlide key={panel.id}>
          <Slide panel={panel} index={index} />
        </SwiperSlide>
      ))}
    </MultiHero>
  )
}
