import type { ReactNode } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { Autoplay } from 'swiper'
import 'swiper/css'
import { useSwiper } from 'swiper/react'
import { NavigationDots, Stack } from '@nordic-web/ui-components'
import { FullScreenSwiper } from '@/components/full-screen-swiper'
import { HeroRatioContainer } from '@/components/hero/hero'
import { NavigationArrow } from '@/components/navigation-arrow'
import { useSwiperActiveIndex, useSwiperSlideCount } from '@/features/video/rich-player/endscreen.hooks'

type MultiHeroProps = {
  children: ReactNode
  autoplayDelay?: number
  loop?: boolean
}

type SwiperNavigationProps = {
  showNavigationArrows: boolean
}

function SwiperNavigation({ showNavigationArrows }: SwiperNavigationProps) {
  const swiper = useSwiper()
  const activeIndex = useSwiperActiveIndex()
  const count = useSwiperSlideCount()

  return (
    <>
      <NavigationArrow direction="left" show={showNavigationArrows} onClick={() => swiper.slidePrev()} />
      <Stack slot="container-end" nwAlignItems="center">
        <NavigationDots activeIndex={activeIndex} count={count} onDotClick={(index) => swiper.slideToLoop(index)} />
      </Stack>
      <NavigationArrow direction="right" show={showNavigationArrows} onClick={() => swiper.slideNext()} />
    </>
  )
}

export function MultiHero({ children, autoplayDelay = 6000, loop = true }: MultiHeroProps) {
  const [showNavigationArrows, setShowNavigationArrows] = useState(false)

  return (
    <HeroRatioContainer
      onMouseEnter={() => setShowNavigationArrows(true)}
      onMouseLeave={() => setShowNavigationArrows(false)}
    >
      <StyledSwiper loop={loop} autoplay={{ delay: autoplayDelay }} modules={[Autoplay]}>
        {children}
        <SwiperNavigation showNavigationArrows={showNavigationArrows} />
      </StyledSwiper>
    </HeroRatioContainer>
  )
}

const StyledSwiper = styled(FullScreenSwiper)({
  overflow: 'visible',
  position: 'relative',
  zIndex: 0,
})
